<template>
    <div>
        <v-container 
            v-if="user.groups.includes('mapping | access')"
            tile>
            <v-card>
                <v-toolbar
                color="cyan"
                dark
                :loading="loading"
                dense>
                    <v-toolbar-title>Commentaren <v-btn @click="toggleEvents()" color="black" class="text-align-right" text>Toggle events</v-btn></v-toolbar-title>
                </v-toolbar>
                <v-list three-line
                    style="max-height:400px; max-width:100%"
                    class="overflow-y-auto overflow-x-auto">
                    <template v-for="item in comments">
                        <v-list-item
                        v-if="item.type == 'status_change' && showEvents == true"
                            dense
                            class="yellow lighten-4"
                            :key="item.id">
                            <v-list-item-content class="yellow lighten-5">
                                <v-list-item-title><v-icon>mdi-label-multiple-outline</v-icon> Statuswijziging (door {{item.action_user.name}})
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary" dark v-on="on" icon><v-icon right color="grey">mdi-information-outline</v-icon></v-btn>
                                        </template>
                                            <ul v-for="(rule, key) in item.data" :key="key">
                                                <strong>Mapping rule {{key+1}}</strong>
                                                <table>
                                                    <thead>
                                                        <th>Variabele</th><th>Waarde</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, key) in rule" :key="key">
                                                            <td>{{key}}</td>
                                                            <td v-if="key == 'Mapcorrelation' && item == '447559001'">Broad to narrow</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447557004'">Exact match</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447558009'">Narrow to broad</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447560006'">Partial overlap</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447556008'">Not mappable</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447561005'">Not specified</td>
                                                            <td v-else>{{item}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr>
                                            </ul>
                                    </v-tooltip>
                                
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{item.created}}
                                    

                                </v-list-item-subtitle>
                                {{item.text}}
                                
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-else-if="item.type == 'comment'"
                            dense
                            class="white"
                            :key="item.id">
                            <v-list-item-content>
                                <v-list-item-title><v-icon>mdi-comment-text-outline</v-icon> Commentaar (door {{item.action_user.name}})</v-list-item-title>
                                <v-list-item-subtitle v-html="item.created"></v-list-item-subtitle>
                                {{item.text}}
                                <v-btn v-if="item.user.id == user.id" color="red lighten-3" @click="deleteComment(item.id)" small>Verwijderen</v-btn>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-else-if="item.type == 'user_change' && showEvents == true"
                            dense
                            class="yellow lighten-4"
                            :key="item.id">
                            <v-list-item-content class="yellow lighten-5">
                                <v-list-item-title><v-icon>mdi-account-arrow-right</v-icon> Toewijzing (door {{item.action_user.name}})
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary" dark v-on="on" icon><v-icon right color="grey">mdi-information-outline</v-icon></v-btn>
                                        </template>
                                            <ul v-for="(rule, key) in item.data" :key="key">
                                                <strong>Mapping rule {{key+1}}</strong>
                                                <table>
                                                    <thead>
                                                        <th>Variabele</th><th>Waarde</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, key) in rule" :key="key">
                                                            <td>{{key}}</td>
                                                            <td v-if="key == 'Mapcorrelation' && item == '447559001'">Broad to narrow</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447557004'">Exact match</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447558009'">Narrow to broad</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447560006'">Partial overlap</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447556008'">Not mappable</td>
                                                            <td v-else-if="key == 'Mapcorrelation' && item == '447561005'">Not specified</td>
                                                            <td v-else>{{item}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr>
                                            </ul>
                                    </v-tooltip>
                                
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="item.created"></v-list-item-subtitle>
                                {{item.text}}
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-list-item
                            v-else-if="item.type == 'task_manager' && showEvents == true"
                            dense
                            class="yellow lighten-4"
                            :key="item.id">
                            <v-list-item-content class="yellow lighten-5">
                                <v-list-item-title>
                                    <v-icon>mdi-account-arrow-right</v-icon> Wijziging via Task Manager (door {{item.action_user.name}})
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="item.created"></v-list-item-subtitle>
                                {{item.text}}
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider :key="item.timestamp"></v-divider>
                    </template>
                </v-list>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import MappingTaskService from '../../services/mapping_task.service'

export default {
    props: {
        project: Object,
        selectedTask: Object
    },
    data() {
        return {
            showEvents : false,
            comments: [],
            loading: false,
        }
    },
    methods: {
        getComments(project_id, task_id) {
            this.loading = true

            if (project_id === undefined) {
                project_id = this.project.id
            }
            if (task_id === undefined) {
                task_id = this.selectedTask.id
            }

            MappingTaskService.get_comments(project_id, task_id, {}).then((response) => {
                if (response === undefined) {
                    this.comments = []
                } else {
                    this.comments = response
                }
                this.loading = false
            })
        },
        deleteComment(id){
            this.$store.dispatch('MappingTasks/deleteComment', id)
        },
        toggleEvents(){
            if(this.showEvents == true){
                this.showEvents = false
            }else{
                this.showEvents = true
            }
        }
    },
    computed: {
        user(){
            return this.$store.state.userData
        }
    },
    created() {
        this.getComments()
    },
    watch: {
        selectedTask () {
            this.getComments()
        }
    },
}
</script>

